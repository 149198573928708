export default function bottomCTA() {
    const contentElement = document.querySelector('.block-full-content .content');
    const readMoreButton = document.querySelector('.btn-read-more');

    if (contentElement && readMoreButton) {
        // Встановіть початковий стан на основі тексту кнопки
        const initialOpen = readMoreButton.innerText === 'Згорнути';
        // contentElement.style.maxHeight = initialOpen ? 'none' : (window.innerWidth < 767 ? '50px' : '100px');
        contentElement.style.overflow = 'hidden';

        readMoreButton.addEventListener('click', function(event) {
            event.preventDefault();
            if (this.innerText === 'Читати більше') {
                contentElement.style.maxHeight = 'none';
                this.innerText = 'Згорнути';
            } else {
                contentElement.style.maxHeight = window.innerWidth < 767 ? '50px' : '100px';
                this.innerText = 'Читати більше';
            }
        });

        contentElement.querySelectorAll('ol').forEach(function(ol) {
          if(!ol) return
          const start = ol.getAttribute('start');
          if (start) {
            ol.style.counterReset = 'custom-counter ' + (parseInt(start, 10) - 1);
          }
        });
    }


}
